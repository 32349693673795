.requiredHighlight {
  outline: 1px solid orange !important;
  outline-offset: -1px;
  border-radius: 4px;
  color: blueviolet;
  border-color: var(--mantine-color-orange-6);
}

.stickyHeader {
  position: sticky;
  top: 0;
  z-index: 100;
  background: #004451;
  color: white;
  width: 100%;
  /* border-bottom: 1px solid var(--mantine-color-gray-3); */
}

.selectStyle {
  color: #ffffffaa;
  background: #004451;
  border: 1px solid #004451;
}