.footer {
  padding: 1em 0.75em;
  color: var(--gray-400);

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: solid 1px var(--border-color);
}

.pages {
  display: flex;
  align-items: center;
  gap: 1em;
}

.numbers {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.numbers button {
  font-size: 0.9em;
  background: none;
  border: solid 1px var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  padding: 0.35em 0.75em 0.25em;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  min-width: 2.5em;
}

.numbers .active {
  background: var(--primary);
  color: white;
}

.numbers .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.numbers button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
