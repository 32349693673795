.row {
  padding-bottom: 1rem;
  break-inside: avoid;
}

.Group {
  border: solid 1px var(--gray-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: white;
}
