:root {
  --primary-50: #ebf5ff;
  --primary-100: #e1effe;
  --primary-200: #c3ddfd;
  --primary-300: #a4cafe;
  --primary-400: #76a9fa;
  --primary-500: #3f83f8;
  --primary-600: #1c64f2;
  --primary-700: #1a56db;
  --primary-800: #1e429f;
  --primary-900: #233876;
  --blue-50: #ebf5ff;
  --blue-100: #e1effe;
  --blue-200: #c3ddfd;
  --blue-300: #a4cafe;
  --blue-400: #76a9fa;
  --blue-500: #3f83f8;
  --blue-600: #1c64f2;
  --blue-700: #1a56db;
  --blue-800: #1e429f;
  --blue-900: #233876;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2a37;
  --gray-900: #111928;
  --red-50: #fdf2f2;
  --red-100: #fde8e8;
  --red-200: #fbd5d5;
  --red-300: #f8b4b4;
  --red-400: #f98080;
  --red-500: #f05252;
  --red-600: #e02424;
  --red-700: #c81e1e;
  --red-800: #9b1c1c;
  --red-900: #771d1d;
  --orange-50: #fff8f1;
  --orange-100: #feecdc;
  --orange-200: #fcd9bd;
  --orange-300: #fdba8c;
  --orange-400: #ff8a4c;
  --orange-500: #ff5a1f;
  --orange-600: #d03801;
  --orange-700: #b43403;
  --orange-800: #8a2c0d;
  --orange-900: #771d1d;
  --yellow-50: #fdfdea;
  --yellow-100: #fdf6b2;
  --yellow-200: #fce96a;
  --yellow-300: #faca15;
  --yellow-400: #e3a008;
  --yellow-500: #c27803;
  --yellow-600: #9f580a;
  --yellow-700: #8e4b10;
  --yellow-800: #723b13;
  --yellow-900: #633112;
  --green-50: #f3faf7;
  --green-100: #def7ec;
  --green-200: #bcf0da;
  --green-300: #84e1bc;
  --green-400: #31c48d;
  --green-500: #0e9f6e;
  --green-600: #057a55;
  --green-700: #046c4e;
  --green-800: #03543f;
  --green-900: #014737;
  --teal-50: #edfafa;
  --teal-100: #d5f5f6;
  --teal-200: #afecef;
  --teal-300: #7edce2;
  --teal-400: #16bdca;
  --teal-500: #0694a2;
  --teal-600: #047481;
  --teal-700: #036672;
  --teal-800: #05505c;
  --teal-900: #014451;
  --indigo-50: #f0f5ff;
  --indigo-100: #e5edff;
  --indigo-200: #cddbfe;
  --indigo-300: #b4c6fc;
  --indigo-400: #8da2fb;
  --indigo-500: #6875f5;
  --indigo-600: #5850ec;
  --indigo-700: #5145cd;
  --indigo-800: #42389d;
  --indigo-900: #362f78;
  --purple-50: #f6f5ff;
  --purple-100: #edebfe;
  --purple-200: #dcd7fe;
  --purple-300: #cabffd;
  --purple-400: #ac94fa;
  --purple-500: #9061f9;
  --purple-600: #7e3af2;
  --purple-700: #6c2bd9;
  --purple-800: #5521b5;
  --purple-900: #4a1d96;
  --pink-50: #fdf2f8;
  --pink-100: #fce8f3;
  --pink-200: #fad1e8;
  --pink-300: #f8b4d9;
  --pink-400: #f17eb8;
  --pink-500: #e74694;
  --pink-600: #d61f69;
  --pink-700: #bf125d;
  --pink-800: #99154b;
  --pink-900: #751a3d;
}

@mixin twoColumns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 2vmax;
  gap: 2vmax;
  max-width: 1200px;
  flex-wrap: wrap;

  .column {
    padding: 1vmax;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 250px;
    max-width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border: 1px solid black;
  }
}
