.list {
  list-style: none;
  margin: 0;
  padding: 0.5em;
  border-bottom: solid 1px var(--teal-500);

  --nav-hover-color: var(--mantine-color-green-6);
  --nav-hover-bg: rgba(64, 192, 87, 0.2);

  --nav-hover-color: var(--teal-900);
  --nav-hover-bg: var(--teal-300);
}

.list a,
.list .tab {
  display: flex;
  align-items: center;
  gap: 1em;
  color: white;
  font-size: 14px;
  text-decoration: none;
  padding: 0.75em 0.75em;
  border-radius: 3px;
}

.list a:hover {
  background: var(--teal-900);
}

.list a:global(.active) {
  background: var(--nav-hover-bg);
  color: var(--nav-hover-color);
}

.list a:global(.active) :global(.mantine-Badge-root) {
  color: var(--teal-900);
  /* border-color: var(--teal-900); */
}

.list a:global(.active) :global(.mantine-Indicator-processing) {
  color: var(--teal-900);
}

.list a:global(.active) :global(.mantine-Indicator-indicator) {
  background-color: #ea5837 !important;
}

.children {
  padding: 0;
  margin-left: 1.25em;
  border: none;
}

.list a :global(.mantine-Badge-root) {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}

.list a :global(.mantine-Group-root) {
  flex-wrap: nowrap;
}