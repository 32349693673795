.section {
  padding: 1.5em 1em;
  /* border: solid 1px red; */
}

.section h4 {
  margin: 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5em;
}

.badge {
  display: inline-flex;
  background: var(--orange-400);
  padding: 0.25em 1em;
  border-radius: 5px;
  color: white;
}

.addQuantity {
  text-align: end;
}

.quantity {
  white-space: nowrap;
  width: 90px;
  padding: 2px 0;
  display: inline-flex;
  align-items: right;
  gap: 0.25rem;
}

.quantity button:disabled {
  background: var(--gray-200);
}

.quantity b {
  min-width: 1.5em;
  display: inline-flex;
  justify-content: center;
}

.timeline {
  padding: 1em 2em;
}

.modalActions {
  padding: 0 0.5rem;
}

.dropdown {
  background: var(--teal-700);
  color: white;
}

.item {
  color: white;
}

.item:hover {
  background: var(--teal-500);
}

.versionBtn {
  cursor: pointer;
}
