.Row {
  display: flex;
  font-size: 12px;
}

.Label {
  font-weight: 700;
  min-width: 250px;
}

.Value {
  color: var(--gray-600);
  white-space: pre-wrap;
}

.Placeholder {
  font-weight: 300;
  color: var(--gray-300);
}
