.terms {
  /* padding: 1rem; */
  margin: 1rem 0;
}

.terms table {
  background: white;
  width: 100%;
  border-collapse: collapse;
  border: none;
  font-size: 12px;
}

.terms td,
.terms th {
  border: solid 1px var(--gray-200);
  padding: 0.5em 1em;
}

.terms th {
  background: var(--gray-200);
  text-align: left;
}

.desc {
  color: var(--gray-400);
}
