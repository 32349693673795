.Spinner {
  margin: 100px 0;
  height: 100px;
  display: flex;
  position: relative;
  justify-content: center;

  div {
    width: 100%;
    position: absolute;
    border-radius: 50%;
    border: 2px solid transparent;
    animation: spin linear infinite;
    border-bottom-color: #00bda3;
  }

  .c1 {
    top: 0px;
    width: 80px;
    height: 80px;
    animation-duration: 0.65s;
  }

  .c2 {
    top: -10px;
    width: 100px;
    height: 100px;
    animation-duration: 0.95s;
  }

  .c3 {
    top: -20px;
    width: 120px;
    height: 120px;
    animation-duration: 1.25s;
  }

  @keyframes spin {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
