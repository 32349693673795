.content {
  margin: 1rem;
}

.details {
  background: white;
  border: solid 1px var(--gray-200);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 14px;

  /* border: solid 1px red; */
}

.details header {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.details h2 {
  margin: 0.5rem 0;
}

.details section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1em 0;
}

.list b {
  font-weight: 500;
  color: var(--teal-700);
}

.Content {
  max-width: 900px;
  padding: 1rem;
}

.Section {
  display: flex;
  gap: 1rem;
  background: white;
  padding: 1rem;
  border: solid 1px var(--gray-200);
  border-radius: 0.5rem;
  margin: 1rem 2rem;
}

.Inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.AddOption {
  padding: 0 2rem;
  text-align: right;
}

.DefaultValue {
  flex: 0;
  white-space: nowrap;
  align-self: flex-end;
  padding-bottom: 0.5rem;
}

.Actions {
  padding: 0 1rem;
  display: flex;
  gap: 1rem;
}

.ViewTerms {
  display: grid;
  grid-template-columns: 300px 1fr 50px;
  gap: 1rem;
  background: white;
  border: solid 1px var(--gray-200);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  padding: 1rem;
}

.Desc {
  font-size: 12px;
  color: var(--gray-400);
}

.Options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.Option {
  position: relative;
}

.Active {
  position: absolute;
  margin-left: -40px;
  margin-top: 10px;
  color: var(--teal-400);
  /* left: -100%; */
}

.logo {
  max-height: 50px;
}

.editBtn {
  float: right;
  margin-left: 1rem;
}
