.login-page {
  --sso-button-width: 450px;
}

.centerColumn {
  width: var(--sso-button-width);
}

.ssoProviderButton {
  background-color: #2b3245;
  color: #bfbfbf;
  border-radius: 5px;
  border: solid 1px #2b3245;
  padding: 10px 10px 10px 10px;
  width: var(--sso-button-width);
  height: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin: 0 10px 0px 0;
    height: 24px;
  }

  .logo {
    margin: 0px 0px 0px 6px;
  }

  &:hover {
    background: #3c445c;
  }
}

.termsAndConditions {
  width: var(--sso-button-width);
}
