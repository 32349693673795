.layout {
  display: grid;
  grid-template-columns: 350px 1fr;
}

.heaeder {
  padding: 0.5em 1em;
}

.layout b {
  font-weight: 500;
}

.badge {
  display: inline-flex;
  background: var(--orange-400);
  padding: 0.25em 1em;
  border-radius: 5px;
  color: white;
}

.content {
  padding: 1rem;
}

.Section {
  overflow: auto;
}

.totals {
  list-style: none;
  margin: 0;
  padding: 1em;
  font-size: 0.9em;
}

.totals li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em 1em;
}

.tab {
  position: relative;
}

.demo {
  color: var(--red-600);
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  position: absolute;
  left: 100%;
  top: -1em;
  margin-left: -1em;
  /* margin: 0.5em; */
}
