.dialog::backdrop {
}
.dialog {
  border: solid 1px var(--gray-400);
  border-radius: 0.5em;
  width: 80%;
}

.dialog.sm {
  width: min(80%, 600px);
}

.dialog.md {
  width: min(80%, 800px);
}

.dialog.xl {
  width: 95%;
}
