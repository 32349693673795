.Layout {
  padding: 1rem;
  background: var(--gray-50);
  min-height: 100vh;
}

.Container {
  max-width: 1180px;
  margin: 0 auto;
}
