.header {
  width: 900px;
  margin: 2rem auto;
}

.page {
  background: white;
  border: solid 1px var(--gray-300);
  padding: 2em 2em;
  width: 900px;
  margin: 2rem auto;
}

.signButton {
  position: absolute;
  margin-top: -1rem;
  margin-left: 0.5rem;
}
