.flex,
.flex-1 {
  display: flex;
  gap: 1em;
  align-items: center;
  flex-wrap: wrap;

  &-1 > * {
    flex: 1;
  }
}

.capitalize {
  text-transform: capitalize;
}

.rounded {
  border-radius: 50vh;
}

.outline {
  border: solid 1px var(--border-color);
  display: inline-flex;
  padding: 0 1em;
}

.w-500 {
  font-weight: 500;
}

.w-600 {
  font-weight: 600;
}

.desc {
  font-size: 0.9em;
  color: var(--gray-500);
}

.gap-05 {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.gap-1 {
  display: flex;
  gap: 1em;
  align-items: center;
}

.thumbs {
  display: flex;
  align-items: center;
  gap: 0.25em;
}

.thumbs img {
  border-radius: 4px;
}
