.Grid {
  /* border: solid 1px red; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.Grid > div:first-child {
  grid-column: 1/3;
}
